.container{
    display:flex;
    flex-direction: column;
    margin:auto;
    width: 600px;
    background: #fff;
    padding-bottom: 30px
  
}

.header {

    display:flex;
    flex-direction: column;
    align-items:center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text{

    /* color: #3c009d; */
    color: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    font-size: 48px;
    font-weight: 700;
}

.underline {

    width: 61px;
    height: 6px;
    /* background: #3c009d; */
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    border-radius: 9px;
}

.inputs{
    margin-top: 55px;
    display:flex;
    flex-direction: column;
    gap: 25px
}

.input{
    display:flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img {

    margin: 0px 30px;
}

.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px
}

.forgot-password{
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span{
     color: #4c00b4;
       cursor: pointer
}

.submit-container{

    display: flex; 
    gap: 30px;
    margin: 60px auto;

}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    /* background: #4c00b4; */
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;

}

.gray {
    background: #eaeaea;
    color: #676767;
}


@media screen and (max-width: 960px) {
    
    
    .container > header {

        width: 100%;
        margin-top: 30px;
       
    }
   
    .input{
        display:flex;
        align-items: center;
        width: 280px;
        height: 40px;
        background: #eaeaea;
        border-radius: 6px;
    }

    .text{
        font-size: 28px;
        font-weight: 200;
    }

    .submit{
        width: 120px;
        height: 35px;
        font-size: 16px;
    }

    .input input{

        font-size: 16px
    }
  } 
  
  @media screen and (max-width: 768px) {

    .container > header {

        width: 100%;
        margin-top: 30px;
       
    }
   
    .input{
        display:flex;
        align-items: center;
        width: 280px;
        height: 40px;
        background: #eaeaea;
        border-radius: 6px;
    }

    .text{
        font-size: 28px;
        font-weight: 200;
    }

    .submit{
        width: 120px;
        height: 35px;
        font-size: 16px;
    }
    .input input{

        font-size: 16px
    }
     }

  
  @media screen and (max-width: 100px) {

    .container > header {

        width: 100%;
        margin-top: 30px;
       
    }
   
    .input{
        display:flex;
        align-items: center;
        width: 280px;
        height: 40px;
        background: #eaeaea;
        border-radius: 6px;
    }

    .text{
        font-size: 28px;
        font-weight: 200;
    }

    .submit{
        width: 120px;
        height: 35px;
        font-size: 16px;
    }
    
    .input input{

        font-size: 16px
    }
  }