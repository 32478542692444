* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;

  }
  
  .home,
  .tutoroffers,
  .subjects,
  .sign-in {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
   
   
  
  }
  
  .tutoroffers {
    background-image: url('/public/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;  
  }
  
  .subjects {
    background-image: url('/public/images/img-3.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
   
  }
  
  .sign-in {
    background-image: url('/public/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  