.BKSlider{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.description {
   
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    
    
}


.description{

    flex-direction: column;
    width: 100%;

}

.description > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }


.description > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

   .carousel-boullt{
    display: flex;
    color: #fff;
    margin-top: 100px;

}
.carousel-boullt span{
 
    margin-left: 10px;
    cursor: pointer;
    box-shadow: 3px 2px 2px rbga(73, 72, 72, 0.338);
 
 }
 

 @media screen and (max-width: 960px) {
    .description > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .description > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .description > p {
      font-size: 30px;
      overflow-wrap: break-word;
      word-break: break-all
    }
  
  }
  